import React from 'react'
import { graphql, Link } from 'gatsby'
import DocLayout from '../components/Layout'
import clsx from 'clsx'
import TableOfContents from '../components/TableOfContents'
import PageSwitcher from '../components/PageSwitcher'

const navigation = [
  {
    title: 'Introduction',
    links: [{ title: 'Getting started', href: '/guides' }],
  },
]

export default function docTemplate({ location, data }: { location: any; data: any }) {
  const html = data.markdownRemark.html
  const headings = data.markdownRemark.headings
  const pathname = location.pathname.replace(/\/+$/, '')
  let allLinks = navigation.flatMap((section) => section.links)
  let linkIndex = allLinks.findIndex((link) => link.href === pathname)
  let previousPage = allLinks[linkIndex - 1]
  let nextPage = allLinks[linkIndex + 1]
  let section = navigation.find((section) => section.links.find((link) => link.href === pathname))

  return (
    <DocLayout location={location} navigation={navigation}>
      <div className="min-w-0 max-w-2xl flex-auto px-4 pt-14 pb-20 lg:max-w-none lg:pr-0 lg:pl-8 xl:px-16">
        <header className="mb-8 space-y-1">
          <div className={clsx('text-3xl text-slate-900 dark:text-white font-medium')}>{section?.title}</div>
        </header>
        <article>
          <div
            className={clsx(
              'prose prose-slate max-w-none dark:prose-invert dark:text-slate-400',
              // headings
              'prose-headings:scroll-mt-28 align-middle prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem]',
              // lead
              'prose-lead:text-slate-500 dark:prose-lead:text-slate-400',
              // links
              'prose-a:font-semibold dark:prose-a:text-indigo-400',
              // pre
              'prose-pre:rounded-xl prose-pre:text-slate-700 prose-pre:bg-slate-100 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10',
              // hr
              'dark:prose-hr:border-slate-800',
            )}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </article>
        <div className="mt-12 border-t border-slate-200 dark:border-slate-800">
          <PageSwitcher previousPage={previousPage} nextPage={nextPage} />
        </div>
      </div>
      <div className="hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto pt-14 pb-16 xl:pr-6">
        <nav aria-labelledby="on-this-page-title" className="w-56">
          <TableOfContents headings={headings} />
        </nav>
      </div>
    </DocLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      headings {
        id
        depth
        value
      }
      frontmatter {
        title
        description
      }
    }
  }
`
